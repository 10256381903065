import React, { useState } from 'react';
import './ProductCard.css';
import ImagePopup from './ImagePopUp';

const ProductCard = ({ item }) => {
    const [popupSrc, setPopupSrc] = useState(null);

    const openPopup = (src) => setPopupSrc(src);
    const closePopup = () => setPopupSrc(null);

    // Proverite da li 'item.images' postoji i nije prazan
    const sortedImages = (item.images || []).sort((a, b) => a.priority - b.priority);

    // Ako nema slika, možete postaviti podrazumevanu sliku ili ne prikazivati deo sa slikama
    const displayImage = sortedImages.length > 0 ? sortedImages[0].url : 'path/to/default-image.jpg';

    return (
        <div className="product-card">
            <div className="product-image-container">
                <img 
                    src={displayImage}
                    alt="Product"
                    className="product-image"
                    onClick={() => sortedImages.length > 0 && openPopup(displayImage)} 
                />
            </div>
            <div className="product-info">
                <h2 className="product-name">{item.name}</h2> 
                <p className="product-price">€{item.price}</p>
            </div>
            {popupSrc && (
                <ImagePopup src={popupSrc} onClose={closePopup} />
            )}
        </div>
    );
};

export default ProductCard;
